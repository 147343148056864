import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowRight, Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicNavItemFieldset from "../../../components/dynamicFieldset/dynamicNavItemsFieldset";
import DynamicOptionsFieldset from "../../../components/dynamicOptionsFieldset/dynamicOptionsFieldset";
import DynamicUserRoleFieldset from "../../../components/dynamicOptionsFieldset/DynamicUserRoleFieldset";
import { NetworkErrorAlert } from "../../../components/infoPopup/infoPopup";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import * as Actions from "../../../constants/baseUrl";

const AdminConfigWizardStepper = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const navigate = useNavigate();
  const userData = useUserData();
  const role = userData.role;

  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const cohortsAlias = SubDomainRouter("cohortsAlias");
  const codeAIAlias = SubDomainRouter("codeAIAlias");
  const startupsAlias = SubDomainRouter("startupsAlias");
  const propertyEventsAlias = SubDomainRouter("propertyEventsAlias");
  const propertiesAlias = SubDomainRouter("propertiesAlias");
  const trainersAlias = SubDomainRouter("trainersAlias");
  const eventsAlias = SubDomainRouter("eventsAlias");

  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [domainData, setDomainData] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [nameAliasData, setNameAliasData] = useState({
    userId: myUserId,
    createdBy: myUserId,
    modifiedBy: myUserId,
  });
  const [navItems, setNavItems] = useState([
    { path: "/", label: "Home" },
    { path: "/", label: "" },
  ]);
  const [userRole, setUserRole] = useState([
    { key: "user", value: "user" },
    { key: "", value: "" },
  ]);
  const [dynamicUrls, setDynamicUrls] = useState([{ key: "", value: "" }]);
  const [countryTypes, setCountryTypes] = useState({ 0: "" });
  const [courseNames, setCourseNames] = useState({ 0: "" });
  const [subcategoryTypes, setSubcategoryTypes] = useState({ 0: "" });
  const [teachingModes, setTeachingModes] = useState({ 0: "" });
  const [paymentTypes, setPaymentTypes] = useState({ 0: "" });
  const [starRatings, setStarRatings] = useState({ 0: "" });
  const [basicPackage, setBasicPackage] = useState({ 0: "" });
  const [proPackage, setProPackage] = useState({ 0: "" });
  const [masterPackage, setMasterPackage] = useState({ 0: "" });
  const [heroSliderImages, setHeroSliderImages] = useState({ 0: "" });

  const myToken = localStorage.getItem("token");

  const populateList = async () => {
    axios
      .get(`${Actions.BASE_URL}configs`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      })
      .then((response) => {
        setNameAliasData(response?.data?.hostsettings?.common?.nameAlias);
        setDomainData(response?.data);
        setLoading(false);
        setNavItems(
          response?.data?.hostsettings?.header?.navItems ?? [
            { path: "/", label: "Home" },
            { path: "/", label: "" },
          ]
        );

        setUserRole(
          response.data?.pagesettings?.auth?.userRole || [
            { key: "user", value: "user" },
            { key: "", value: "" },
          ]
        );

        setDynamicUrls(
          response.data?.hostsettings?.common?.dynamicUrls || [
            { key: "", value: "" },
          ]
        );

        setCountryTypes(
          response?.data?.hostsettings?.common?.enums?.countryTypes ?? {
            100: "",
          }
        );
        setCourseNames(
          response?.data?.hostsettings?.common?.enums?.courseNames ?? {
            100: "",
          }
        );
        setSubcategoryTypes(
          response?.data?.hostsettings?.common?.enums?.subcategoryTypes ?? {
            100: "",
          }
        );
        setTeachingModes(
          response?.data?.hostsettings?.common?.enums?.teachingModes ?? {
            0: "",
          }
        );
        setPaymentTypes(
          response?.data?.hostsettings?.common?.enums?.paymentTypes ?? { 0: "" }
        );
        setStarRatings(
          response?.data?.hostsettings?.common?.enums?.starRatings ?? { 0: "" }
        );
        setBasicPackage(
          response.data?.hostsettings?.common?.enums?.basicPackage
        );
        setProPackage(response.data?.hostsettings?.common?.enums?.proPackage);
        setMasterPackage(
          response.data?.hostsettings?.common?.enums?.masterPackage
        );

        setHeroSliderImages(
          response.data?.pagesettings?.home?.heroSliderImages ?? { 0: "" }
        );
        setCustomFields(
          response?.data?.hostsettings?.common?.customfields || [
            { key: "", value: "" },
          ]
        );
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const addField = () => {
    setUserRole((prevRole) => [...prevRole, { key: "", value: "" }]);
  };

  const removeField = (index) => {
    setUserRole((prevRole) => prevRole.filter((_, i) => i !== index));
  };

  const updateUserRoles = (index, updatedRole) => {
    setUserRole((prevRole) => {
      const newRole = [...prevRole];
      newRole[index] = updatedRole;
      return newRole;
    });
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setNameAliasData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    const { _id, ...data } = {
      ...domainData,
      id: domainData?.id || domainData?._id?.$oid,
      type: domainData.type,
      userId: domainData.userId,
      appsettings: {},
      hostsettings: {
        title: nameAliasData?.title,
        name: nameAliasData?.name,
        logo: nameAliasData?.logo,
        link: nameAliasData?.link,
        favicon: nameAliasData?.favicon,
        header: {
          logo: nameAliasData?.logo,
          navItems,
        },
        common: {
          homePageRoute: nameAliasData?.homePageRoute,
          defaultRegisterForm: nameAliasData?.defaultRegisterForm,
          nameAlias: nameAliasData,
          dynamicUrls,
          customfields: customFields,
          enums: {
            countryTypes,
            courseNames,
            subcategoryTypes,
            paymentTypes,
            teachingModes,
            starRatings,
            basicPackage,
            proPackage,
            masterPackage,
          },
        },
        footer: {
          logo: nameAliasData?.logo,
          fromEmail: nameAliasData?.fromEmail,
          adminEmail: nameAliasData?.adminEmail,
          supportEmail: nameAliasData?.supportEmail,
        },
      },
      pagesettings: {
        home: {
          heroSliderHeading: nameAliasData?.heroSliderHeading,
          heroSliderText: nameAliasData?.heroSliderText,
          heroSliderImages,
        },
        auth: {
          userRole,
        },
      },
    };

    axios
      .post(`${Actions.BASE_URL}configs/new`, data)
      .then((response) => {
        successAlert();
        populateList();
        navigate("/");
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setReqLoading(false));
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Data Updated successfully
            </h5>
          </div>
        );
      },
    });
  };
  console.log(nameAliasData);

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container
          fluid="lg"
          className="profile-edit config-page demosEditSection pt-0 formMain"
        >
          <div className="demosAddFormHeader text-center">
            <h2 className="addFormHeading text-capitalize">
              Add Configuration
            </h2>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <ul className="demosEditList">
                <li>
                  <button
                    className={
                      step === 1 ? "active stepstriggers" : "stepstriggers"
                    }
                    onClick={() => setStep(1)}
                  >
                    <span>1</span>
                  </button>
                </li>
                <li>
                  <button
                    className={
                      step === 2 ? "active stepstriggers" : "stepstriggers"
                    }
                    onClick={() => setStep(2)}
                  >
                    <span>2</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="demosAddForm">
            <Form onSubmit={handleSubmit}>
              {/* Step */}
              {step === 1 && (
                <Row className="mx-0">
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        required
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Type"
                        value={nameAliasData?.title}
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                      />
                      <label htmlFor="title">Site Title</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        required
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Type"
                        value={nameAliasData?.name}
                        onChange={(e) =>
                          handleFieldChange("name", e.target.value)
                        }
                      />
                      <label htmlFor="name">Site Name</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        required
                        type="text"
                        id="host"
                        name="host"
                        placeholder="Type"
                        value={nameAliasData?.Host}
                        onChange={(e) =>
                          handleFieldChange("host", e.target.value)
                        }
                      />
                      <label htmlFor="Host">Site Host</label>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        required
                        type="text"
                        id="logo"
                        name="logo"
                        placeholder="Logo Url"
                        value={nameAliasData?.logo}
                        onChange={(e) =>
                          handleFieldChange("logo", e.target.value)
                        }
                      />
                      <label htmlFor="logo">Logo Url</label>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="favicon"
                        name="favicon"
                        placeholder="FavIcon URL"
                        value={nameAliasData?.favicon}
                        onChange={(e) =>
                          handleFieldChange("favicon", e.target.value)
                        }
                      />
                      <label htmlFor="favicon">FavIcon URL</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="adminEmail"
                        name="adminEmail"
                        placeholder="adminEmail"
                        value={nameAliasData?.adminEmail}
                        onChange={(e) =>
                          handleFieldChange("adminEmail", e.target.value)
                        }
                      />
                      <label htmlFor="adminEmail">Admin Email</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="supportEmail"
                        name="supportEmail"
                        placeholder="supportEmail"
                        value={nameAliasData?.supportEmail}
                        onChange={(e) =>
                          handleFieldChange("supportEmail", e.target.value)
                        }
                      />
                      <label htmlFor="supportEmail">Support Email</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="trainersListID"
                        name="trainersListID"
                        placeholder="trainersListID"
                        value={nameAliasData?.trainersListID}
                        onChange={(e) =>
                          handleFieldChange("trainersListID", e.target.value)
                        }
                      />
                      <label htmlFor="trainersListID">Trainers List ID</label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="subscriptionListID"
                        name="subscriptionListID"
                        placeholder="subscriptionListID"
                        value={nameAliasData?.subscriptionListID}
                        onChange={(e) =>
                          handleFieldChange(
                            "subscriptionListID",
                            e.target.value
                          )
                        }
                      />
                      <label htmlFor="subscriptionListID">
                        Subscription List ID
                      </label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="fromEmail"
                        name="fromEmail"
                        placeholder="fromEmail"
                        defaultValue={`contact@${window.location.host}`}
                        value={nameAliasData?.fromEmail}
                        onChange={(e) =>
                          handleFieldChange("fromEmail", e.target.value)
                        }
                      />
                      <label htmlFor="fromEmail">
                        Add Email to send mails from
                      </label>
                    </Form.Group>
                  </Col>

                  {/* Default HomePage Path */}
                  <h6 className="theme-text-2 fw-normal mt-3">
                    Default HomePage
                  </h6>
                  <hr />

                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="homePageRoute"
                        className={`form-control form-select text-capitalize`}
                        value={nameAliasData?.homePageRoute}
                        name="homePageRoute"
                        onChange={(e) =>
                          handleFieldChange("homePageRoute", e.target.value)
                        }
                      >
                        <option value="">Select Default Homepage</option>
                        <option value="/">Default Homepage Page</option>
                        <option value="/courses">{coursesAlias} Page</option>
                        <option value="/demos">{demosAlias} Page</option>
                        <option value="/startup/items">
                          {startupsAlias} Page
                        </option>
                        <option value="/contact">Contact Page</option>
                        <option value="/blogs">Blogs Page</option>
                        <option value="/code-ai">{codeAIAlias}</option>
                        <option value="/cohort/itemss">
                          {cohortsAlias} Page
                        </option>
                        <option value="/propertyevent/items">
                          {propertyEventsAlias} Page
                        </option>
                        <option value="/events">{eventsAlias} Page</option>
                      </Form.Select>
                      <Form.Label
                        className="form-label"
                        htmlFor="defaultRegisterForm"
                      >
                        Default Home Page
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* default register form */}
                  <h6 className="theme-text-2 fw-normal mt-4">
                    Default Register {demosAlias}/{coursesAlias} Form
                  </h6>
                  <hr />
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="defaultRegisterForm"
                        className={`form-control form-select text-capitalize`}
                        value={nameAliasData?.defaultRegisterForm}
                        name="defaultRegisterForm"
                        onChange={(e) =>
                          handleFieldChange(
                            "defaultRegisterForm",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Default Register Form</option>
                        <option value="registerPopup">Register Popup</option>
                        <option value="registerAcceleratorForm">
                          Register Accelerator Form
                        </option>
                      </Form.Select>
                      <Form.Label
                        className="form-label"
                        htmlFor="defaultRegisterForm"
                      >
                        Default Register Form
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Row className="mx-0">
                    <Col
                      md={12}
                      className="d-flex justify-content-between align-items-center gap-3 mt-4 mb-2"
                    >
                      <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                        Navbar Items
                      </h6>
                      <Button
                        className="clickable add-button ms-auto rounded-circle d-flex"
                        onClick={() =>
                          setNavItems((prevNavItems) => [
                            ...prevNavItems,
                            { path: "/", label: "" },
                          ])
                        }
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <hr />
                    <Col md={12} className="mt-3">
                      <Row>
                        {navItems.map((navItem, index) => (
                          <DynamicNavItemFieldset
                            key={index}
                            index={index}
                            fields={navItems}
                            setFields={setNavItems}
                          />
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  {["siteadmin", "superadmin"].includes(role) && (
                    <>
                      <Accordion>
                        <Accordion.Item eventKey="0" className="border-0">
                          <Accordion.Header className="theme-text-2 border-bottom">
                            <h5 className="theme-text-2">More Items</h5>
                          </Accordion.Header>
                          <Accordion.Body className="px-0">
                            <Row className="mx-0">
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /demos
                                </span>{" "}
                                <ArrowRight /> {demosAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /courses
                                </span>{" "}
                                <ArrowRight /> {coursesAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /events
                                </span>{" "}
                                <ArrowRight /> {eventsAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /trainers
                                </span>{" "}
                                <ArrowRight /> {trainersAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /contact
                                </span>{" "}
                                <ArrowRight /> Contact
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /propertyevent/items
                                </span>{" "}
                                <ArrowRight /> {propertyEventsAlias}{" "}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /code-ai
                                </span>{" "}
                                <ArrowRight /> {codeAIAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /property/items
                                </span>{" "}
                                <ArrowRight /> {propertiesAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /blog
                                </span>{" "}
                                <ArrowRight /> Blog
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /corporates
                                </span>{" "}
                                <ArrowRight /> Corporates
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /reviews
                                </span>{" "}
                                <ArrowRight /> Reviews
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /startup/items
                                </span>{" "}
                                <ArrowRight /> {startupsAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /cohort/items
                                </span>{" "}
                                <ArrowRight /> {cohortsAlias}
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /items/meetup
                                </span>{" "}
                                <ArrowRight /> meetups
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /founder/items
                                </span>{" "}
                                <ArrowRight /> founders
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /speaker/items
                                </span>{" "}
                                <ArrowRight /> speakers
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /mentor/items
                                </span>{" "}
                                <ArrowRight /> mentors
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /items/blogger
                                </span>{" "}
                                <ArrowRight /> bloggers
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /intern/items
                                </span>{" "}
                                <ArrowRight /> interns
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /freelancer/items
                                </span>{" "}
                                <ArrowRight /> freelancers
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /partner/items
                                </span>{" "}
                                <ArrowRight /> partners
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /items/sponsor
                                </span>{" "}
                                <ArrowRight /> sponsors
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /NRI-startup/items
                                </span>{" "}
                                <ArrowRight /> NRI startups
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                className="mb-1 text-capitalize"
                              >
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /items/bets-tester
                                </span>{" "}
                                <ArrowRight /> beta testers
                              </Col>
                              <Col md={6} className="mb-1 text-capitalize">
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /items/corporate-startup
                                </span>{" "}
                                <ArrowRight /> corporate startups
                              </Col>
                              <Col md={6} className="mb-1 text-capitalize">
                                <span className="theme-text-2 italicInput text-lowercase">
                                  /service-provider/items
                                </span>{" "}
                                <ArrowRight /> service providers
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )}

                  <Col
                    md={12}
                    className="mt-3 d-flex justify-content-end gap-2"
                  >
                    <Button
                      type="button"
                      className="demosEditBtn"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              )}
              {/* Step */}
              {/* page_settings */}
              {step === 2 && (
                <Row className="mx-0">
                  <Col className="mb-3 px-2">
                    <h5 className="theme-text flex-grow-1">Home Page</h5>
                    <hr />
                  </Col>

                  <Col md={12} className="px-2">
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="heroSliderHeading"
                        name="heroSliderHeading"
                        placeholder="Hero Slider Heading"
                        value={nameAliasData?.heroSliderHeading}
                        onChange={(e) =>
                          handleFieldChange("heroSliderHeading", e.target.value)
                        }
                      />
                      <label htmlFor="heroSliderHeading">
                        Hero Slider Heading
                      </label>
                    </Form.Group>
                  </Col>

                  <Col md={12} className="px-2">
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="heroSliderText"
                        name="heroSliderText"
                        placeholder="Hero Slider Description"
                        value={nameAliasData?.heroSliderText}
                        onChange={(e) =>
                          handleFieldChange("heroSliderText", e.target.value)
                        }
                      />
                      <label htmlFor="heroSliderText">
                        Hero Slider Description
                      </label>
                    </Form.Group>
                  </Col>

                  {/* heroSliderImages */}
                  <Col
                    md={12}
                    className="d-flex justify-content-between gap-3 mt-4 mb-2 px-2"
                  >
                    <h6 className="theme-text-2 flex-grow-1">
                      Hero Slider Images
                    </h6>
                    <Button
                      className="clickable add-button ms-auto rounded-circle d-flex"
                      onClick={() => {
                        setHeroSliderImages((prevState) => {
                          const newIndex =
                            Object.keys(prevState)?.length.toString();
                          return {
                            ...prevState,
                            [newIndex]: "",
                          };
                        });
                      }}
                    >
                      <span className="addBttn d-block">+</span>
                    </Button>
                  </Col>
                  <hr />
                  <Col md={12} className="mt-3 px-2">
                    <Row>
                      {heroSliderImages &&
                        Object.keys(heroSliderImages)?.map((index) => (
                          <DynamicOptionsFieldset
                            ColNum={12}
                            label={"Slider Image"}
                            key={index}
                            index={index}
                            fields={heroSliderImages}
                            setFields={setHeroSliderImages}
                          />
                        ))}
                    </Row>
                  </Col>
                  <hr />

                  {["siteadmin", "superadmin"].includes(role) && (
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2 px-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1">
                          Sections HTML
                        </h6>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            as={"textarea"}
                            rows={8}
                            style={{
                              height: "auto",
                            }}
                            id="section_html"
                            name="section_html"
                            placeholder="section_html"
                            value={nameAliasData?.section_html}
                            onChange={(e) =>
                              handleFieldChange("section_html", e.target.value)
                            }
                          />
                          <label htmlFor="section_html">
                            Add HTML for section
                          </label>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            as={"textarea"}
                            rows={8}
                            style={{
                              height: "auto",
                            }}
                            id="footer_html"
                            name="footer_html"
                            placeholder="footer_html"
                            value={nameAliasData?.footer_html}
                            onChange={(e) =>
                              handleFieldChange("footer_html", e.target.value)
                            }
                          />
                          <label htmlFor="footer_html">
                            Add HTML for new footer (will replace original
                            footer)
                          </label>
                        </Form.Group>
                      </Col>
                    </>
                  )}

                  {["siteadmin", "superadmin"].includes(role) && (
                    <>
                      <Col className="mb-3 px-2 mt-4">
                        <h5 className="theme-text flex-grow-1">Auth Page</h5>
                        <hr />
                      </Col>
                      {/* UserRoles */}
                      <Col
                        md={12}
                        className="d-flex justify-content-between align-items-center gap-3 mt-4 px-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize m-0">
                          User Roles
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={addField}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>

                      <hr />
                      <Col md={12} className="mt-3 px-2">
                        <Col md={12} className="mt-3">
                          {userRole?.map((role, index) => (
                            <DynamicUserRoleFieldset
                              key={index}
                              field={role}
                              onFieldChange={(updatedRole) =>
                                updateUserRoles(index, updatedRole)
                              }
                              onRemove={() => removeField(index)}
                            />
                          ))}
                        </Col>
                      </Col>
                    </>
                  )}

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="prev demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Prev
                    </Button>

                    <Button type="submit" className="next demosEditBtn ">
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default AdminConfigWizardStepper;
