import { CopyAllOutlined } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { PencilSquare, PlusCircle, Trash3Fill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import API from "../../../components/API/API";
import CustomLoader from "../../../components/loader/loader";
import * as Actions from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";

const ConfigManagement = () => {
  const myToken = localStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  // Get data
  const getConfigs = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}configs/all`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setConfigs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getConfigs();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            setReqLoading(true);
            API("delete", `configs/${id}`)
              .then((res) => {
                getConfigs();
              })
              .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data || "Error deleting config");
              })
              .finally(() => {
                setReqLoading(false);
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const siteName = SubDomainRouter("siteName");

  return (
    <>
      <Helmet>
        <title>
          Config Management | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <div className="my-groups mb-4">
        {reqLoading ? (
          <>
            <span className="register-loader"> </span>
            <span className="spinnerOverlay" />
          </>
        ) : null}

        {loading ? (
          <CustomLoader />
        ) : (
          <Row className="align-items-center mx-0">
            <Col
              md={12}
              className="heading-text d-flex justify-content-between gap-1 align-items-start"
            >
              <div>
                <h4 className="theme-text-2">Configurations</h4>
              </div>
              <div className="d-flex justify-content-md-end px-0 gap-2 text-capitalize">
                <Link
                  className="add-button btn d-flex align-items-center"
                  to="/super-admin-dashboard/config/add"
                >
                  <PlusCircle />
                  <span className="d-lg-block text-nowrap d-none">
                    &nbsp; Create config
                  </span>
                </Link>
              </div>
            </Col>
            <Col md={12} className="my-3">
              {configs?.length > 0 ? (
                <Table className="striped" bordered responsive>
                  <thead>
                    <tr>
                      <th className="bg-theme bg-opacity-50 text-light">
                        Host/Domain Name
                      </th>
                      <th className="bg-theme bg-opacity-50 text-light action-wrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {configs?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.host}</td>
                        <td className="d-flex align-items-center justify-content-center gap-2">
                          <Link
                            to={`/super-admin-dashboard/config/edit/${
                              item?.id || item._id.$oid
                            }`}
                            className="btn-link theme-text text-center me-1"
                            title="Edit"
                          >
                            <PencilSquare />
                          </Link>
                          <Link
                            to={`/super-admin-dashboard/config/clone/${
                              item?.id || item._id.$oid
                            }`}
                            className="btn-link theme-text text-center me-1"
                            title="Clone"
                          >
                            <CopyAllOutlined />
                          </Link>
                          <Link
                            to={"#"}
                            className="btn-link text-danger text-center p-0"
                            title="Delete"
                            onClick={() =>
                              deleteItem(item?.id || item._id.$oid)
                            }
                          >
                            <Trash3Fill />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No Configs Founds...</p>
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ConfigManagement;
