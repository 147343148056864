import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Send } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import GeneralMsgModal from "../generalMsgModal/generalMsgModal";
import "./subscriptionModal.css";
import useUserData from "../useUserDetails/useUserDetails";
import TokenDetails from "../useUserDetails/useTokenDetails";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

const SubscriptionModal = () => {
  const userData = useUserData();
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const listId = SubDomainRouter("subscriptionListID");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    lists: [listId],
    type: "contact",
    approval_status: "",
    entityRelation: [{ emaillist: listId }],
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    city: "",
    title: "",
    industry: "",
  });

  const [modalMessage, setModalMessage] = useState("success");
  const [modalShow, setModalShow] = useState(false);
  const [msgModalShow, setMsgModalShow] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const newsletterPopupShown = localStorage.getItem("newsletterPopupShown");

  const onShow = () => {
    setModalShow(true);
  };

  const onHide = () => {
    localStorage.setItem("newsletterPopupShown", "true");
    setModalShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!listId) {
      setLoading(false);
      return;
    }

    const data = {
      ...formData,
      lists: [listId],
      entityRelation: [{ emaillist: listId }],
    };

    axios
      .post(`${Actions.BASE_URL}contacts/${myUserId}/${listId}`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData((prevState) => ({
          ...prevState,
          email: "",
        }));
        setModalMessage("success");
      })
      .catch((error) => {
        console.error("Subscription error:", error.response);
        setModalMessage("error");
      })
      .finally(() => {
        localStorage.setItem("newsletterPopupShown", "true");
        setLoading(false);
        setMsgModalShow(true);
      });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      email: userData?.email,
      phone: userData?.phone,
      whatsapp: userData?.whatsapp,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!newsletterPopupShown) {
      setTimeout(() => {
        setModalShow(true);
      }, 20_000);
    }
  }, [newsletterPopupShown]);

  return (
    <>
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Button className="sub-btn add-button" onClick={onShow}>
        Subscribe
      </Button>

      <div className="subscribe-container mx-2">
        <Modal
          show={modalShow}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Subscribe to our newsletter
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="my-2">
              Enter you details below to subscribe to our newsletter.
            </p>

            <Form onSubmit={handleSubmit} className="subscribe-form z-3 pt-2">
              <Row className="mx-0">
                <Col md={12} className="mb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      type="email"
                      name="email"
                      required
                      placeholder="Enter your email..."
                      value={formData?.email || ""}
                      onChange={(e) =>
                        handleFieldChange("email", e.target.value)
                      }
                    />
                    <Form.Label htmlFor="email">Email</Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Accordion>
                    <Accordion.Item eventKey="0" className="border-0 mb-3">
                      <Accordion.Header className="theme-text-2 border-0 border-bottom">
                        <h5 className="theme-text-2 m-0">More Details</h5>
                      </Accordion.Header>
                      <Accordion.Body className="px-0 row mx-0">
                        <Col md={6} className="mb-3">
                          <Form.Group className="form-floating">
                            <Form.Control
                              type="first_name"
                              name="first_name"
                              placeholder="Enter your first name..."
                              value={formData?.first_name || ""}
                              onChange={(e) =>
                                handleFieldChange("first_name", e.target.value)
                              }
                            />
                            <Form.Label htmlFor="first_name">
                              First Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                          <Form.Group className="form-floating">
                            <Form.Control
                              type="last_name"
                              name="last_name"
                              placeholder="Enter your last name..."
                              value={formData?.last_name || ""}
                              onChange={(e) =>
                                handleFieldChange("last_name", e.target.value)
                              }
                            />
                            <Form.Label htmlFor="last_name">
                              Last Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating">
                            <Form.Control
                              type="phone"
                              name="phone"
                              placeholder="Enter your phone..."
                              value={formData?.phone || ""}
                              onChange={(e) =>
                                handleFieldChange("phone", e.target.value)
                              }
                            />
                            <Form.Label htmlFor="phone">Phone</Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating">
                            <Form.Control
                              type="whatsapp"
                              name="whatsapp"
                              placeholder="Enter your whatsapp..."
                              value={formData?.whatsapp || ""}
                              onChange={(e) =>
                                handleFieldChange("whatsapp", e.target.value)
                              }
                            />
                            <Form.Label htmlFor="whatsapp">Whatsapp</Form.Label>
                          </Form.Group>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>

                <Col md={12} className="d-flex justify-content-end">
                  <Button
                    className={`add-button d-flex align-items-center gap-1 justify-content-center`}
                    type="submit"
                    disabled={loading}
                  >
                    <Send />
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </div>

      <GeneralMsgModal
        modalmessage={modalMessage}
        show={msgModalShow}
        onHide={() => {
          setMsgModalShow(false);
          onHide();
        }}
      />
    </>
  );
};

export default SubscriptionModal;
