import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useUserData from "../useUserDetails/useUserDetails";
import { AdminMenuDataMainSite } from "./adminmenudata";
import "./navbarstyle.css";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

function AdminNavBar() {
  const userData = useUserData();
  const role = userData.role?.toLowerCase();

  const location = useLocation();

  const config = useSelector((state) => state.hostSettings.value);
  const homePageRoute = SubDomainRouter("homePageRoute");
  const logoSrc = SubDomainRouter("logo");

  const navItemsData = config?.header?.navItems ?? [
    { path: "/", label: "Home" },
    { path: "/contact", label: "Contact" },
  ];

  return (
    <Navbar expand="lg" className="align-items-center py-0 adminNavbar">
      <Container fluid="lg" className="h-100">
        <Link
          className="navbar-brand"
          to={`${homePageRoute === "/home" ? "/" : homePageRoute || "/"}`}
        >
          <img src={`${logoSrc}`} alt="" />
        </Link>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto my-lg-0 my-3 align-items-center">
            {navItemsData?.map((item, index) => (
              <Link
                key={index}
                style={{ order: item?.index ?? index }}
                to={item.path}
                className={`nav-link text-capitalize ${
                  location.pathname === item.path ? "active-link" : ""
                } ${item.path?.includes(homePageRoute) ? "set-first" : ""}`}
              >
                {item.label}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>

        <div className="right d-flex align-items-center gap-3">
          <NavDropdown
            className="dropDownImg d-flex align-items-center justify-content-center"
            title={
              <img
                src={
                  userData?.photo_image_url ||
                  `https://placehold.co/280x280/42a49f/white?text=${
                    userData?.first_name?.slice(0, 1) ||
                    userData?.type?.slice(0, 1) ||
                    "I"
                  }`
                }
                loading="lazy"
                alt=""
              />
            }
            id="basic-nav-dropdown"
          >
            {AdminMenuDataMainSite.slice(0, 5)?.map((item, index) => (
              <Link className="dropdown-item" key={index} to={item.Url}>
                {item.title}
              </Link>
            ))}

            {["admin", "siteadmin", "superadmin"].includes(role) && (
              <>
                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/admin/configs">
                  Admin Dashboard
                </Link>
              </>
            )}

            {role === "superadmin" && (
              <Link
                className="dropdown-item"
                to="/super-admin-dashboard/config-management"
              >
                Super Admin Dashboard
              </Link>
            )}

            {window.location.host !== "mata.ameerpetit.com" && (
              <>
                <NavDropdown.Divider />
                {AdminMenuDataMainSite.slice(5)?.map((item, index) => (
                  <Link className="dropdown-item" key={index} to={item.Url}>
                    {item.title}
                  </Link>
                ))}
              </>
            )}
            {["siteadmin", "superadmin"].includes(role) && (
              <>
                <NavDropdown.Divider />
                <Link
                  className="dropdown-item"
                  to="/coordinator-dashboard/my-trainers"
                >
                  Coordinator Dashboard
                </Link>
              </>
            )}
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => {
                localStorage.removeItem("isValidUser");
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
      </Container>
    </Navbar>
  );
}

export default AdminNavBar;
