import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import {
  Check2Circle,
  PencilSquare,
  PlusCircle,
  Share,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { BiCopy } from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../components/API/API";
import { NetworkErrorAlert } from "../../../components/infoPopup/infoPopup";
import CustomLoader from "../../../components/loader/loader";
import SocialShare from "../../../components/share/socialShare";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import ICON from "../../../img/page-icon.svg";

export default function Page() {
  const currentDomain = window.location.hostname;

  const myToken = localStorage.getItem("token");
  const userData = useUserData();
  const role = userData.role?.toLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const pagesAlias = SubDomainRouter("pagesAlias");
  const homePageRoute = SubDomainRouter("homePageRoute");
  const uppercaseTitle = pagesAlias.toLocaleUpperCase();

  const entities = "items";
  const entityType = "page";

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [configData, setConfigData] = useState(null);
  const [displayedCards, setDisplayedCards] = useState(8);

  const populateList = async () => {
    setLoading(true);
    try {
      await API("get", "configs").then((res) => {
        setConfigData(res);
      });

      const data = await API("get", `items/${entityType}`);
      setItems(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            API("delete", `${entities}/${entityType}/${id}`, {
              headers: {
                Authorization: `Bearer ${myToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }).then((res) => {
              populateList();
            });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  console.log(configData);

  // toggle handleHomepage
  const handleHomepageToggle = (path) => {
    const { _id, ...reqConfigData } = {
      ...configData,
      id: configData?.id || configData?._id?.$oid,
      hostsettings: {
        ...configData?.hostsettings,

        common: {
          ...configData?.hostsettings?.common,
          nameAlias: {
            ...configData?.hostsettings?.common?.nameAlias,
            homePageRoute: path,
          },
        },
      },
    };
    setReqLoading(true);

    API(
      "put",
      `configs/${configData?.id || configData?._id?.$oid}`,
      reqConfigData
    )
      .then((response) => {
        populateList();
        successAlert();
        // window.location.reload();
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setReqLoading(false));
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Default Homepage Updated successfully
            </h5>
          </div>
        );
      },
    });
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items?.length
          ? prevDisplayedCards + 4
          : items?.length
      );
    };

    return (
      <>
        <Helmet>
          <title>Pages {siteName ? siteName : window.location.host}</title>
        </Helmet>

        {reqLoading ? (
          <>
            <span className="register-loader"> </span>
            <span className="spinnerOverlay" />
          </>
        ) : null}
        <Row className="justify-content-md-start justify-content-center">
          <Col className="mb-3" lg={10} sm={8}>
            <h3 className="pageSubHeading mb-0 text-sm-start text-center text-capitalize">
              {pagesAlias ? pagesAlias : "Pages"}
            </h3>
          </Col>
          {[
            "admin",
            "siteadmin",
            "superadmin",
            "trainer",
            "training-coordinator",
          ].includes(role) ? (
            <Col
              lg={2}
              sm={4}
              className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
            >
              <Link
                to={`/${entityType}s/add`}
                type="button"
                className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
              >
                <PlusCircle /> &nbsp; Add
              </Link>
            </Col>
          ) : null}

          {loading ? (
            <CustomLoader />
          ) : (
            <>
              {items?.length > 0 ? (
                items?.slice(0, displayedCards)?.map((item, index) => (
                  <Col
                    key={index}
                    xxl={3}
                    xl={4}
                    md={6}
                    sm={10}
                    className="mt-2 mb-3"
                  >
                    <Card className="demoCard p-3 d-flex w-100 flex-column justify-content-between">
                      <div className="w-100">
                        <div
                          className="image-wrapper d-flex align-items-center justify-content-center position-relative"
                          style={{ height: "6rem" }}
                        >
                          <Image
                            className="h-100"
                            src={ICON}
                            //   width={"100%"}
                            height={"100%"}
                            alt="img"
                          />
                        </div>
                        <h3 className="itemName my-2 px-3 text-center">
                          <Link
                            to={`/${entityType}s/details/${item.id}`}
                            className="detailLink text-center"
                          >
                            {item?.data?.page_title}
                          </Link>
                        </h3>
                      </div>

                      <div className="w-100">
                        <div className="d-flex gap-2 justify-content-center align-items-center">
                          <SocialShare
                            url={`https://${currentDomain}/${item?.type}s/details/${item?.id}/`}
                            iconsSize={30}
                            title={item?.data?.title}
                            image={item?.data?.listing_image_url}
                            description={item?.data?.description}
                          />

                          <Link
                            to={`/share/items_${entityType}/${item.id}`}
                            className="entity-share-btn d-flex align-items-center cursor-pointer"
                            title="Share"
                          >
                            <Share />
                          </Link>
                        </div>
                        <div className="d-flex w-100 flex-wrap justify-content-center gap-2 mt-3">
                          <Link
                            to={`/${entityType}s/details/${item.id}`}
                            className="add-button btn w-100 flex-1 d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                          >
                            View More
                          </Link>
                          {role === "admin" ||
                          role === "siteadmin" ||
                          role === "superadmin" ||
                          (item.userId &&
                            item.userId?.toLowerCase() === myUserId) ||
                          item?.data?.userId?.toLowerCase() === myUserId ? (
                            <>
                              <Link
                                to={`/${entityType}s/edit/${item.id}`}
                                className="add-button btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                              >
                                <span className="d-sm-block d-none">Edit</span>
                                <PencilSquare className="d-sm-none d-block" />
                              </Link>
                              <Link
                                to={`/${entityType}s/clone/${item.id}`}
                                className="add-button btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                              >
                                <span className="d-sm-block d-none">Clone</span>
                                <BiCopy className="d-sm-none d-block" />
                              </Link>
                              <Link
                                to="#"
                                className="actionDltBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                onClick={() => deleteItem(item.id)}
                              >
                                <span className="d-sm-block d-none">
                                  Delete
                                </span>
                                <Trash className="d-sm-none d-block" />
                              </Link>
                              {homePageRoute !==
                                `/${entityType}s/details/${item.id}` && (
                                <Button
                                  onClick={() =>
                                    handleHomepageToggle(
                                      `/${entityType}s/details/${item.id}`
                                    )
                                  }
                                  className="add-button btn w-100 flex-1 d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                                >
                                  Set As Homepage
                                </Button>
                              )}
                              {homePageRoute ===
                                `/${entityType}s/details/${item.id}` && (
                                <span className="flex align-items-center gap-2 justify-content-center theme-text-2 fs-6 fw-medium btn w-fit mx-auto">
                                  <Check2Circle className="theme-text fs-5" />{" "}
                                  Site Homepage
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <p>No data found</p>
              )}
            </>
          )}
        </Row>
        {!loading && displayedCards < items?.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={handleShowMore}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
