import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Actions from "../../constants/baseUrl";
import EmailFooter from "../emailTemplate/emailFooter";
import EmailHeader from "../emailTemplate/emailHeader";
import EmailQuoteBody from "../emailTemplate/getQuoteBody";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";

const PostYourNeed = (props) => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const teachingModes = useSelector((state) => state.hostSettings.value)?.common
    ?.enums?.teachingModes;

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");
  const trainersListID = SubDomainRouter("trainersListID");
  const fromEmail = SubDomainRouter("fromEmail");
  const userData = useUserData();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    city: "",
    course: "",
    email: "",
    phone: "",
    name: "",
    teaching_mode: "",
    message: "",
  });

  const campaignFields = {
    campaign_name: "Trainer Campaign",
    description: "Quote By Learner",
    userId: myUserId,
    type: "campaign",
    emaillists: [],
    entityRelation: [],
    subject: `Enquiry from ${title}`,
    body: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    scheduled_date: "",
    scheduled_time: "",
    campaign_sent_status: "",
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newFormData = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const mappedTeamBodyData = EmailQuoteBody(formData);
    const mappedTrainerBodyData = EmailQuoteBody(formData, trainersListID);
    const footer_email_content = EmailFooter(logo, title);

    const data = {
      ...campaignFields,
      userId: myUserId,
      type: "email",
      to: [adminEmail, supportEmail],
      message: `${header_email_content} ${mappedTeamBodyData} ${footer_email_content}`,
      Attachments: null,
      Template: null,
      IsHTML: true,
    };

    const dataTrainers = {
      ...campaignFields,
      userId: myUserId,
      type: "campaign",
      emaillists: [trainersListID],
      body: `${header_email_content} ${mappedTrainerBodyData} ${footer_email_content}`,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        axios
          .post(`${Actions.BASE_URL}campaigns`, dataTrainers, {
            headers: {
              Authorization: `Bearer ${myToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setLoading(false);
            successCampaignAlert();
          })
          .catch((error) => {
            setLoading(false);
            console.log(error.response);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      });
  };

  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              Your Request was received.
            </h5>
            <p className="text-center lh-1">
              <small>
                Thank You for showing Interest&nbsp;<b>{formData?.name}</b>
                &nbsp; We will contact you soon!
              </small>
            </p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                props.onHide();
                onClose();
                handleFieldChange("teaching_mode", "");
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    const username = `${
      userData?.first_name
        ? userData?.first_name !== undefined && userData?.first_name
        : ""
    } ${
      userData?.last_name
        ? userData?.last_name !== undefined && userData?.last_name
        : ""
    }`;

    setFormData({
      ...formData,
      city: userData?.city,
      phone: userData?.phone,
      email: userData?.email,
      name: username !== " " ? username : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="h6 theme-text text-capitalize"
        >
          Contact Us
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mx-0" onSubmit={(e) => handleSubmit(e)}>
          {/* <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              className="italicInput"
              id="course"
              required
              value={formData?.course}
              onChange={(e) => handleFieldChange("course", e.target.value)}
              placeholder="Enter Event Title"
              name="course"
            />
            <Form.Label className="formLabel" htmlFor="course">
              Enter Your Course
            </Form.Label>
          </Form.Group>
          <Form.Group className="form-floating mb-3">
            <Form.Select
              className="form-control"
              aria-label="event type"
              value={formData?.teaching_mode}
              required
              onChange={(e) =>
                handleFieldChange("teaching_mode", e.target.value)
              }
              id="subcategorylevel2"
              name="subcategorylevel2"
            >
              <option value="">Teaching Mode</option>
              {teachingModes && Object.values(teachingModes)?.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group> */}

          {formData?.teaching_mode.toLowerCase() === "in-class room" && (
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                className="italicInput"
                id="city"
                value={formData?.city}
                required
                onChange={(e) => handleFieldChange("city", e.target.value)}
                placeholder="Enter City"
                name="city"
              />
              <Form.Label className="formLabel" htmlFor="city">
                Enter Your City
              </Form.Label>
            </Form.Group>
          )}

          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              className="italicInput"
              id="name"
              value={formData?.name}
              required
              onChange={(e) => handleFieldChange("name", e.target.value)}
              placeholder="Enter You Name"
              name="name"
            />
            <Form.Label className="formLabel" htmlFor="name">
              Enter Your Name
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="email"
              className="italicInput"
              value={formData?.email}
              id="email"
              required
              onChange={(e) => handleFieldChange("email", e.target.value)}
              placeholder="Enter Event Title"
              name="email"
            />
            <Form.Label className="formLabel" htmlFor="email">
              Enter Your Email Address
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="phone"
              className="italicInput"
              id="Phone"
              value={formData?.phone}
              required
              onChange={(e) => handleFieldChange("phone", e.target.value)}
              placeholder="Enter Event Title"
              name="Phone"
            />
            <Form.Label className="formLabel" htmlFor="Phone">
              Enter Your Phone No.
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              as={"textarea"}
              rows={3}
              type="message"
              className="italicInput"
              id="message"
              value={formData?.message}
              required
              onChange={(e) => handleFieldChange("message", e.target.value)}
              placeholder="Enter Event Title"
              name="message"
            />
            <Form.Label className="formLabel" htmlFor="message">
              Enter Your Message
            </Form.Label>
          </Form.Group>
          <div className="btn-wrap d-flex align-items-center justify-content-end">
            <Button type="submit" className="add-button text-center">
              Submit
            </Button>
          </div>
          {/* <Alert className="p-2 mb-0 alert-theme-2 lh-sm mt-4">
            Your request will be sent to hundreds of trainers.
          </Alert> */}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default PostYourNeed;
